<template>
  <div class="main">
    <div class="wrapper pt-4">
      <div class="surface bg-white shadow d-flex flex-column px-4 pt-5 pb-4 mt-4 w-100 top" v-if="!emailSent">
        <h2 class="text-center text-sm-left">Reset your password</h2>
        <p class="small text-muted">Enter your email address below and we'll send a special reset password link to your inbox.</p>
        <form class="d-flex flex-column mt-2" @submit.prevent="submit" novalidate>
          <div class="form-group">
            <label class="auth-labels text-uppercase small pl-1" for="email">Email address*</label>
            <div :class="{ error: v$.email.$errors.length }">
              <input type="email" class="form-control" name="email" v-model="v$.email.$model" @input="v$.email.$touch" @keyup="formCheck">
              <div v-if="responseError" class="error-msg">{{ responseMessage }}</div>
              <div v-if="responseSuccess" class="error-msg text-green">{{ responseMessage }}</div>
              <div class="input-errors" v-for="error of v$.email.$errors" :key="error.$uid">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-primary ml-auto mt-3" :disabled="disabledButton" @click.prevent="sendEmail">Send Recovery Email</button>
        </form>
      </div>
      <div class="surface bg-white shadow d-flex flex-column px-4 pt-5 pb-4 mt-4 w-100 top" v-if="emailSent">
            <h2 class="text-center text-sm-left">Recovery Email Sent</h2>
            <p class="small text-muted">An email containing instructions to reset your password has been sent to <span class="text-email">{{ email }}</span></p>
            <p class="small text-muted">If you did not receive the email, please <span class="text-link">contact us</span> to recover your account.</p>
        </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email, maxLength, helpers } from '@vuelidate/validators'

export default {
  name: 'forgot-password',
  data () {
    return {
      email: '',
      disabledButton: true,
      responseSuccess: false,
      responseError: false,
      responseMessage: '',
      emailSent: false
    }
  },
  validations () {
    return {
      email: {
        required: helpers.withMessage('Email is required.', required),
        email: helpers.withMessage('This email format is not valid.', email),
        maxLength: maxLength(100)
      }
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  validationConfig: { $autoDirty: true },
  methods: {
    formCheck () {
      var formErrors = document.getElementsByClassName('error-msg')
      if (formErrors.length > 0 || this.email === '') {
        this.disabledButton = true
      } else {
        this.disabledButton = false
      }

      this.responseSuccess = false
      this.responseError = false
      this.responseMessage = ''
    },
    sendEmail (evt) {
      var data = new FormData()
      data.append('email', this.email)
      this.axios
        .post(process.env.VUE_APP_API + '/session/forgot', data)
        .then((response) => {
          this.responseSuccess = true
          if (response && (response?.status === 201 || response?.status === 200)) {
            this.emailSent = true
          } else {
            this.responseMessage = response.data.msg
          }
        })
        .catch(() => {
          // this.responseError = true
          // this.responseMessage = error.response.data.msg || error.response.data.message
          this.responseSuccess = true
          this.responseMessage = 'If this email address exists in our registries, it will receive a password reset email in a few minutes'
        })
    }
  }
}
</script>
